import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Heading,
  Link,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FaInstagram, FaTiktok, FaFacebook, FaEtsy, FaAmazon, FaGoodreads } from "react-icons/fa";
import { RiLink } from "react-icons/ri";
import { TbBrandThreads } from "react-icons/tb";

const Navbar = () => {
  const bgColor = useColorModeValue("transparent", "transparent");

  return (
    <Box bg={bgColor} p={4} shadow="md">
      <Flex
        maxW="container.xl"
        mx="auto"
        align="center"
        direction={{ base: "column", md: "row" }}
      >
        <Heading
          as="h2"
          size="lg"
          color="white"
          display={{ base: "none", md: "block" }}
        >
          <Link
            as={RouterLink}
            to="/"
            _hover={{
              textDecoration: "none",
              fontFamily: "Megrim, sans-serif",
            }}
          >
            <Image
              src="/logo.png"
              alt="Liana Valerian Logo"
              height="100px"
              filter="invert(1)"
            />
          </Link>
        </Heading>

        <Flex direction="column" align="center" flex="1">
          {/* Main Navigation Links Row */}
          <Wrap
            spacing="12px"
            justify="center"
            direction="row"
            wrap="wrap"
          >
            <WrapItem>
              <NavLink to="/about">About</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/books">Books</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/reviews">Reviews</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/art">Art</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/readings">Readings</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/contact">Contact</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/shop">Shop</NavLink>
            </WrapItem>
            <WrapItem>
              <NavLink to="/newsletter">Newsletter</NavLink>
            </WrapItem>
          </Wrap>

          {/* Social Media Icons Row */}
          <Flex mt={4} justify="center" wrap="wrap" gap="12px">
            <Link href="https://www.instagram.com/liana.valerian" isExternal>
              <FaInstagram size="24px" color="white" />
            </Link>
            <Link href="https://www.tiktok.com/@liana.valerian" isExternal>
              <FaTiktok size="24px" color="white" />
            </Link>
            <Link href="https://www.threads.net/@liana.valerian" isExternal>
              <TbBrandThreads size="24px" color="white" />
            </Link>
            <Link href="https://bit.ly/lianavalerianfbpage" isExternal>
              <FaFacebook size="24px" color="white" />
            </Link>
            <Link href="https://www.etsy.com/shop/LianaValerian" isExternal>
              <FaEtsy size="24px" color="white" />
            </Link>
            <Link href="https://www.amzn.to/3NDkNI2" isExternal>
              <FaAmazon size="24px" color="white" />
            </Link>
            <Link href="https://www.linktr.ee/lianavalerian" isExternal>
              <RiLink size="24px" color="white" />
            </Link>
            <Link href="https://www.goodreads.com/author/show/50272055.Liana_Valerian" isExternal>
              <FaGoodreads size="24px" color="white" />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const NavLink = ({ to, children }) => {
  const color = useColorModeValue("whiteAlpha.900");
  const hoverColor = useColorModeValue("#d791a6");

  return (
    <Link
      as={RouterLink}
      to={to}
      p={2}
      mx={2}
      fontSize="md"
      fontWeight="bold"
      color={color}
      _hover={{ textDecoration: "none", color: hoverColor }}
    >
      {children}
    </Link>
  );
};

export default Navbar;
