// src/theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Cormorant Garamond, serif",
    heading: "Megrim, sans-serif",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "1.875rem",
    "3xl": "2.25rem",
    "4xl": "3rem",
    "5xl": "4rem",
    "6xl": "5rem",
  },
  styles: {
    global: {
      body: {
        bgImage: "url('/background.png')",
        bgSize: "cover",
        bgRepeat: "no-repeat",
        bgPosition: "center",
        minHeight: "100vh",
        color: "white",
      },
    },
  },
});

export default theme;
