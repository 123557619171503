import React, { useEffect } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const NewsletterSignup = () => {
  useEffect(() => {
    // Load the MailerLite script dynamically
    (function(w, d, e, u, f, l, n) {
      w[f] = w[f] || function() {
        (w[f].q = w[f].q || []).push(arguments);
      };
      l = d.createElement(e);
      l.async = 1;
      l.src = u;
      n = d.getElementsByTagName(e)[0];
      n.parentNode.insertBefore(l, n);
    })(window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');

    // Initialize the MailerLite form
    window.ml('account', '1086646');
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  return (
    <Box
      p={4}
      textAlign="center"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap={8}
    >
      <Heading as="h1" size="xl">
        Newsletter Sign-up
      </Heading>

      <Text mt={4}>
        Sign up for my newsletter to receive updates and special offers!
      </Text>

      {/* MailerLite embedded form */}
      <div className="ml-embedded" data-form="7uMDrY"></div>
    </Box>
  );
};

export default NewsletterSignup;
