import React, { useState } from "react";
import {
  Box,
  Link,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Heading,
} from "@chakra-ui/react";
import { TfiEmail } from "react-icons/tfi";

import { PiLinktreeLogoFill } from "react-icons/pi";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://docs.google.com/forms/u/1/d/e/1FAIpQLSeVO59-IhbHKQqHIOCCZnANmsXemChCru-_lKqEmSzc8CxR9w/formResponse?pli=1"; // Replace with your Google Form ID
    form.method = "POST";
    form.target = "hidden_iframe";

    const addField = (name, value) => {
      const field = document.createElement("input");
      field.type = "hidden";
      field.name = name;
      field.value = value;
      form.appendChild(field);
    };

    addField("entry.1167981001", formData.name); // Replace with your entry IDs from Google Form
    addField("entry.1712219621", formData.email); // Replace with your entry IDs from Google Form
    addField("entry.435380497", formData.message); // Replace with your entry IDs from Google Form

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    setSubmitted(true);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
      >
        <Heading as="h1" size="xl" mb={10}>
          Contact Me!
        </Heading>

        <Box
          maxWidth="800px"
          bg="rgba(255, 255, 255, 0.6)"
          p={6}
          borderRadius="md"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center" mb="10px">
            <TfiEmail size="24" color="black" style={{ marginRight: "8px" }} />
            <Text fontSize="md" color="black" fontWeight="bold">
              liana.valerian@gmail.com
            </Text>
          </Box>

          <Box display="flex" alignItems="center" mb="10px">
            <PiLinktreeLogoFill
              size="28"
              color="black"
              style={{ marginRight: "8px" }}
            />
            <Link
              href={"https://www.linktr.ee/lianavalerian"}
              fontSize="md"
              color="black"
              fontWeight="bold"
              target="_blank"
            >
              Linktree
            </Link>
          </Box>

          {submitted ? (
            <Box>
              <Heading as="h2" size="md" mb={4} color="black">
                Thank you for your message!
              </Heading>
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <FormControl mb={4}>
                <FormLabel htmlFor="name" color="black">
                  Name
                </FormLabel>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel htmlFor="email" color="black">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel htmlFor="message" color="black">
                  Message
                </FormLabel>
                <Textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <Button
                mt={4}
                bgColor="#d791a6"
                type="submit"
                fontWeight="bold"
                fontSize="lg"
              >
                Send
              </Button>
            </form>
          )}
          <iframe name="hidden_iframe" style={{ display: "none" }}></iframe>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
