// src/components/reviews.js
import React from "react";
import { Box, Heading, SimpleGrid, Image } from "@chakra-ui/react";

const Reviews = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h1" size="xl" mb={10}>
        Reviews
      </Heading>

      <Heading as="h2" size="lg" mb={5}>
        The Gates of Hell - Book 0.5
      </Heading>
      <ReviewsGrid reviews={book1Reviews} />

      <Heading as="h2" size="lg" mt={10} mb={5}>
        The River of Fire - Book 1
      </Heading>
      <ReviewsGrid reviews={book2Reviews} />
    </Box>
  );
};

const ReviewsGrid = ({ reviews }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={10}
      mb={10}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {reviews.map((review, index) => (
        <Review key={index} review={review} />
      ))}
    </SimpleGrid>
  );
};

const Review = ({ review }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Image
        src={review.image}
        alt={review.name}
        borderRadius="md"
        width="600px"
      />
    </Box>
  );
};

const book1Reviews = [
  {
    name: "Review 1",
    image: "/65.png",
  },
  {
    name: "Review 2",
    image: "/66.png",
  },
  {
    name: "Review 3",
    image: "/67.png",
  },
  {
    name: "Review 4",
    image: "/158.png",
  },
  {
    name: "Review 5",
    image: "/159.png",
  },
  {
    name: "Review 6",
    image: "/160.png",
  },
  {
    name: "Review 7",
    image: "/161.png",
  },
  {
    name: "Review 8",
    image: "/310.jpeg",
  },
  {
    name: "Review 9",
    image: "/311.jpeg",
  },
  {
    name: "Review 10",
    image: "/313.jpeg",
  },
  {
    name: "Review 11",
    image: "/323.jpeg",
  },
  {
    name: "Review 12",
    image: "/333.jpeg",
  },
  {
    name: "Review 15",
    image: "/311.png",
  },
  {
    name: "Review 22",
    image: "/327.png",
  },
  {
    name: "Review 23",
    image: "/328.png",
  },
  {
    name: "Review 24",
    image: "/329.png",
  },
  // Add more reviews for Book 1
];

const book2Reviews = [
  {
    name: "Review 4",
    image: "/64.png",
  },
  {
    name: "Review 13",
    image: "/444.jpeg",
  },
  {
    name: "Review 14",
    image: "/318.png",
  },
  {
    name: "Review 16",
    image: "/312.png",
  }, {
    name: "Review 17",
    image: "/313.png",
  },
  {
    name: "Review 18",
    image: "/314.png",
  },
  {
    name: "Review 19",
    image: "/315.png",
  },
  {
    name: "Review 20",
    image: "/316.png",
  },
  {
    name: "Review 21",
    image: "/317.png",
  },
  {
    name: "Review 25",
    image: "/330.png",
  },
  {
    name: "Review 26",
    image: "/331.png",
  },
  {
    name: "Review 27",
    image: "/332.png",
  },
  {
    name: "Review 28",
    image: "/333.png",
  },
  {
    name: "Review 29",
    image: "/334.png",
  },
  // Add more reviews for Book 2
];

export default Reviews;
